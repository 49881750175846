exports.components = {
  "component---src-pages-arbs-api-tsx": () => import("./../../../src/pages/arbs-api.tsx" /* webpackChunkName: "component---src-pages-arbs-api-tsx" */),
  "component---src-pages-bookie-tsx": () => import("./../../../src/pages/bookie.tsx" /* webpackChunkName: "component---src-pages-bookie-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-middles-api-tsx": () => import("./../../../src/pages/middles-api.tsx" /* webpackChunkName: "component---src-pages-middles-api-tsx" */),
  "component---src-pages-oc-need-update-tsx": () => import("./../../../src/pages/oc-need-update.tsx" /* webpackChunkName: "component---src-pages-oc-need-update-tsx" */),
  "component---src-pages-odds-api-tsx": () => import("./../../../src/pages/odds-api.tsx" /* webpackChunkName: "component---src-pages-odds-api-tsx" */),
  "component---src-pages-oddsclicker-tsx": () => import("./../../../src/pages/oddsclicker.tsx" /* webpackChunkName: "component---src-pages-oddsclicker-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-valuebets-api-tsx": () => import("./../../../src/pages/valuebets-api.tsx" /* webpackChunkName: "component---src-pages-valuebets-api-tsx" */)
}

